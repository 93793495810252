<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'homepage'}" />

				<ion-title slot="start" @click="$router.push({name: 'homepage'})" style="cursor:pointer;"><ion-label>Academy</ion-label></ion-title>

				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="mt-2">
				<ion-row class="buttons-wrapper mt-1">
					<template v-for="filter in themesFilters" :key="filter.name">
						<ion-col :size="Math.floor(12/themesFilters.filter(f=>f.isShown).length)">
							<ion-button v-if="filter.isShown" class="button-block" color="danger" :class="{active: searchQuery == filter.searchQuery}" @click="searchQuery = searchQuery == filter.searchQuery ? '' : filter.searchQuery ">{{filter.text}}</ion-button>
						</ion-col>
					</template>
				</ion-row>
				<ion-searchbar placeholder="Vyhľadaj témy" v-model="searchQuery" show-cancel-button="never"></ion-searchbar>

				<ion-segment class="mb-1" :value="activeTab" @ionChange="activeTab = $event.detail.value">
					<ion-segment-button value="all">
						<ion-label>Všetky</ion-label>
					</ion-segment-button>

					<ion-segment-button value="style" :disabled="availableTags && !availableTags.includes('style')">
						<ion-label>Style</ion-label>
					</ion-segment-button>

					<ion-segment-button value="frontend" :disabled="availableTags && !availableTags.includes('frontend')">
						<ion-label>Frontend</ion-label>
					</ion-segment-button>

					<ion-segment-button value="backend" :disabled="availableTags && !availableTags.includes('backend')">
						<ion-label>Backend</ion-label>
					</ion-segment-button>
				</ion-segment>

				<h3 class="ion-text-center mt-2">Témy</h3>
				<ion-row class="mb-2 ion-justify-content-end">
					<div class="a-go-wrapper" @click="$router.push('/youtube-theme')">
						Prejsť na Youtube lekcie
						<ion-icon class="ml-1 mr-1" :icon="arrowForwardOutline"></ion-icon>
					</div>
				</ion-row>

				<w-loadable class="container" :items="filteredThemesByTags" skelet="none" messageEmpty="Aktuáľnemu hľadaniu nevyhovujú žiadné témy">
					<ion-row class="themes">
						<ion-col v-for="theme in filteredThemesByTags" :key="theme.id"  size="12" size-sm="6" size-md="4">
							<ion-card @click="$router.push({name: 'theme', params: {themeSlug: theme.slug}})">
								<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
									<img v-if="theme.image?.path" :src="theme.image?.path">
									<img v-else src="./img/placeholder-img.jpg">
								</ion-card-header>

								<hr>

								<ion-card-title>{{ theme.name }}</ion-card-title>
								<ion-chip color="primary" v-for="tag in (theme.tags ?? [] )" :key="tag">{{ tag }}</ion-chip>
								<ion-text color="primary" class="bottom-button">Začať</ion-text>
							</ion-card>
						</ion-col>
					</ion-row>
				</w-loadable>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { personCircleOutline, logInOutline, arrowForwardOutline } from 'ionicons/icons'
import { mapGetters} from 'vuex'

const api = {
	read: () => wAxios.get_auth_data(`v1/themes`),
}

export default {
	data() {
		return {
			arrowForwardOutline,
			personCircleOutline,
			logInOutline,
			filteredThemesByTags: null,
			availableTags: null,
			themes: null,
			activeTab: 'all',
			searchQuery: "",
			themesFilters: [
				{
					searchQuery: 'openlab',
					text: 'Openlab',
					isShown: true
				},
				{
					searchQuery: 'wezeo',
					text: 'Wezeo',
					isShown: false
				},
				{
					searchQuery: 'powerplay',
					text: 'PowerPlay',
					isShown: false
				}
			],
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		]),
	},

	async created() {
		try {
			this.themes = await api.read()
			this._filterThemes()
		} catch (error) {
			this.themes = {error: error}
		}
	},

	methods: {
		search(e) {
			this.searchQuery = e.target.value
		},

		_filterThemes() {
			let themes = this.themes
			let search = this.searchQuery.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")

			if (this.searchQuery?.length) {
				themes = themes.filter(theme => {
					const tags = theme.tags.map(tag => tag.toLowerCase().slice(0, this.searchQuery.length))
					return tags.includes(search) || theme.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(search)
				})
			}

			this.availableTags = []
			themes.forEach(theme => this.availableTags.push(...theme.tags))

			if (this.activeTab != 'all') {
				themes = themes.filter(theme => {
					const tags = theme.tags.map(tag => tag.toLowerCase())
					return tags.includes(this.activeTab)
				})
			}

			this.filteredThemesByTags = themes
		},
	},

	watch: {
		searchQuery() {
			this._filterThemes()
		},

		activeTab() {
			this._filterThemes()
		}
	}
}
</script>

<style lang="sass" scoped>
.buttons-wrapper
	padding: 0
	max-width: 650px
	margin: auto

	ion-button
		opacity: 0.6
		margin: 0
		border-radius: 3px
		box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, .4)
		height: 35px
		&.active
			opacity: 1

ion-row
	margin-top: 5px
	max-width: 900px
	margin: auto

ion-col
	--ion-grid-column-padding: 5px

	ion-card
		padding-bottom: 45px
		height: 100%

		img
			height: 80px

		ion-card-title
			font-size: 14px
			margin-bottom: 5px
			font-weight: 400
			color: var(--ion-color-dark)


</style>
